<script>
    import General from '@/services/General'
    import { required, minLength, numeric } from "vuelidate/lib/validators"
    import moment from "moment"
    import vue2Dropzone from "vue2-dropzone"
    import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'
    import Swal from "sweetalert2";
    import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
    import "vue2-dropzone/dist/vue2Dropzone.min.css"
    import "vue-multiselect/dist/vue-multiselect.min.css"

    export default {
        components:{
            vueDropzone: vue2Dropzone,
        },
        props: {
            popupsData: {
                type: Object,
                default: null
            },
        },
        data() {
            return {
                showModal: false,
                tryingToSubmit: false,
                submitted: false,
                date: new Date(),
                popup: {
                    id: null,
                    images:[]
                },

                dropzoneOptions: {
                    url: '/',
                    autoProcessQueue: false,
                    addRemoveLinks: true,
                    thumbnailWidth: 150,
                    thumbnailMethod: 'contain',
                    maxFilesize: 2,
                    acceptedFiles:'image/*'
                },
               // show_add_image_primary:false,
                actionTextOption: [],
                csrf_token: localStorage.getItem('csrf_token')
            };
        },

        methods: {
            shownModal(){
                this.syncData()
            },
            syncData(){
                this.popup.id = this.popupsData.id
                this.popup.images = this.popupsData.images
            },
            refreshPopupData() { 
                this.$emit('onRefresh') //event from parent
            },

            async reloadPopupData(){
               this.$refs.myVueDropzone.removeAllFiles();
               const data = `id=${this.popup.id}`;
               const response = await General.getHypePopUp(data);

               if(response.data.popups[0]){
                    this.popup.id = response.data.popups[0].id
                    this.popup.images = response.data.popups[0].images
               }
            },

            deleteHypePopUp(id) {

            Swal.fire({
                title: "Delete Image?",
                text: "This image will be deleted!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Remove!",
            }).then((result) => {
                if (result.value) {
                    General.deleteHypePopUpImage(id)
                        .then((response) => {
                            const res = response.data ? response.data : false;
                            const error = response.data.error ? response.data.error : 'Failed';
                            if (res) {
                                Swal.fire("Remove Image", "Image successfully removed!", "success");
                                this.reloadPopupData();
                                this.refreshPopupData();
                            } else {
                                Swal.fire("Fail!", error, "warning");
                            }
                        })
                        .catch(error => {
                            this.error = error.response.data.error ? error.response.data.error : "Delete Failed";
                            this.failedmsg(this.error)
                        })
                }
            });
        },


            async addHypePopupImage(){
                this.tryingToSubmit = true
                this.submitted = true

                let formData = new FormData();
                formData.append('popup_id', this.popup.id)
                formData.append('csrf_token', this.csrf_token)
                formData.append('_method', 'POST')
                formData.append('file', this.popup.file)

                General.addHypePopUpImage(formData)
                    .then((response) => {
                        const res = response.data.data ? response.data.data : false;
                        const error = response.data.error ? response.data.error : '';
                        if(res){
                            this.successmsg('Image uploaded!');
                            this.reloadPopupData();
                            this.refreshPopupData();
                        }else{
                            this.failedmsg('Failed to upload an image !')
                        }
                    })
                    .catch(error => {
                        const resData = error.response.data.error ? error.response.data.error : 'Failed';
                        const errorMsg = Array.isArray(resData) ? resData[0] : resData;
                        this.failedmsg(errorMsg)
                    })
                    .finally(() => {
                        this.tryingToSubmit = false
                    })

                
            },
            closeModal() {
                this.showModal = false
                this.show_add_image_primary = false
            },
            removeImage(){
                this.$refs['file-input'].reset()
            },

            resetProps(){
                this.submitted = false
                this.popup.name = ''
                this.popup.action = ''
                this.popup.action_text = ''
                this.popup.type = ''
                this.popup.file = null
            },
            selectFile(files){
                setTimeout(() => {
                    let queuedFiles = this.$refs.myVueDropzone.getQueuedFiles();
                    if (queuedFiles.length > 1) {
                        this.failedmsg('You can only have one image')
                        files.forEach((value) => {
                            this.$refs.myVueDropzone.removeFile(value)
                        });
                    }
                    this.popup.file = queuedFiles[0];
                }, 250);
            },
            getactionTextOptions(){
                General.getactionTextOptions()
                    .then((response) => {
                        this.actionTextOption = response.data.data ? response.data.data : []
                    })
                    .catch(error => {
                        this.actionTextOption = []
                    })
            }
        },
    };
</script>

<template>
    <b-modal  @shown="shownModal()" id="hype_popup_photos" size="md" v-model="showModal" @hidden="resetProps" title="Additional Image" title-class="font-18">
    <form @submit.prevent="addHypePopupImage">
        <div class="row">
          <div class="col-12">
                 <div class="mb-3">
                     <label class="control-label form-label">Upload Image</label>
                     <vue-dropzone
                             id="dropzone"
                             ref="myVueDropzone"
                             :use-custom-slot="true"
                             :options="dropzoneOptions"
                             @vdropzone-files-added="selectFile"
                             no-progress-bar
                     >
                         <div class="dropzone-custom-content">
                             <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                             <h4>Drop file here or click to upload.</h4>
                         </div>
                     </vue-dropzone>
                 </div>


                <div class="mb-3">
                    <label class="control-label form-label">Images</label>
                     <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                               <label class="control-label form-label" v-for="image in this.popup.images" :key="image.id" :value="image.image">
                                    <img :src="image.image" alt="" width="200" class="img-thumbnail" />
                                      <a href="javascript:void(0);" class="px-2 text-danger"
                                            v-b-tooltip.hover title="Remove"
                                            @click="deleteHypePopUp(image.id)">
                                            <i class="uil uil-trash-alt font-size-18"></i>
                                      </a>
                                      <br>
                               </label>

                            </li>



                     </ul>
                </div>


          </div>
        </div>

        <input type="hidden" name="csrf_token" v-model="csrf_token">
      </form>

        <template v-slot:modal-footer>
            <b-button variant="light" @click="closeModal">Close</b-button>
            <b-button variant="primary" @click="addHypePopupImage" :disabled="tryingToSubmit">
                <b-spinner v-show="tryingToSubmit" small></b-spinner>
                Upload
            </b-button>
        </template>

    </b-modal>
 
</template>